import * as React from 'react';
import './GroupDetails.css';
import { AppStore } from '../../../../stores';
import { inject, observer } from 'mobx-react';
import GroupService from '../../../../services/GroupService';
import { IGroupMemberInfo } from '../../../../interfaces/IGroupMemberInfo';

export interface IProps {
  id: string;
  appStore?: AppStore;
}

export interface IState {
}


@inject('appStore')
@observer
export default class GroupDetails extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  public componentWillReceiveProps(props: IProps): void {
    if (props.id && props.id != this.props.id) {
      GroupService.getGroupMemberInfoForGroup(props.id).then((groupMembersInfo: IGroupMemberInfo[]) => {
        console.log(groupMembersInfo);
      });

    }
  }

  render() {

    return (
      <div>
        <div
          className="groupEditPanelWrapper"
          style={{
            visibility: this.props.appStore.groupToView != undefined ? "visible" : "hidden"
          }}
          onClick={() => {
            this.props.appStore.groupToView = undefined;
          }}
        />
        <div
          className="groupEditPanel"
          style={{
            right: this.props.appStore.groupToView != undefined ? 0 : -620
          }}
        >
          <div className="editPanelCloseBtn"
            onClick={() => this.props.appStore.groupToView = undefined}
          />
          <h3>Gruppe detailer</h3>

        </div>
      </div>
    );
  }
}