import * as React from 'react';
import './GroupsPanel.css';
import { AppStore } from '../../../stores';
import { inject, observer } from 'mobx-react';
import AdminService from '../../../services/AdminService';
import { IUser } from '../../../interfaces/IUser';
import UserSearchPopup from '../../../mindjuiceApp/userSearchPopup/UserSearchPopup';

export interface IProps {
  id: string;
  appStore?: AppStore;
  save: () => void;
  delete: () => void;
}

export interface IState {
  searchValue: string;
  usersToRender: JSX.Element[];
  loadingUsers: boolean;
  showMembers: boolean;
}

export interface IState {
  selectedAudience: "all" | "teams" | "users";
}

@inject('appStore')
@observer
export default class GroupsPanel extends React.Component<IProps, IState> {

  constructor(props) {
    super(props);
    this.state = {
      selectedAudience: "all",
      searchValue: "",
      usersToRender: [],
      loadingUsers: false,
      showMembers: false
    }
  }

  render() {
    const members = [];
    if (this.props.appStore.groupToEdit?.members?.length > 0 && this.state.showMembers) {
      this.props.appStore.groupToEdit.members.forEach((member: string) => {
        members.push(
          <div
            key={"groupMember-" + member}
            className="emailLabel"
          >
            {member}
            <div
              className="emailLabelDeleteBtn"
              onClick={() => {
                this.props.appStore.groupToEdit.members = this.props.appStore.groupToEdit?.members.filter(savedMember => savedMember !== member);
              }}
            />
          </div>
        );
      });
    }
    return (
      <div>
        <div
          className="groupEditPanelWrapper"
          style={{
            visibility: this.props.appStore.groupToEdit != undefined ? "visible" : "hidden"
          }}
          onClick={() => {
            this.props.appStore.groupToEdit = undefined;
          }}
        />
        <div
          className="groupEditPanel"
          style={{
            right: this.props.appStore.groupToEdit != undefined ? 0 : -620
          }}
        >
          <div className="editPanelCloseBtn"
            onClick={() => this.props.appStore.groupToEdit = undefined}
          />
          <h3>Opret gruppe</h3>
          {this.props.appStore.groupToEdit != undefined &&
            <div style={{
              position: "relative",
              float: "left",
              width: "100%"
            }}>
              <div className="inputFieldLabel">Gruppenavn</div>
              <input
                type="text"
                value={this.props.appStore.groupToEdit.name}
                onChange={(event: any) => {
                  this.props.appStore.groupToEdit.name = event.target.value;
                }}
              />
              <div className="inputFieldLabel">Besked til medlemmer</div>
              <textarea
                onChange={(event: any) => this.props.appStore.groupToEdit.description = event.target.value}
              >
                {this.props.appStore.groupToEdit?.description}
              </textarea>

              <div
                className="inputFieldLabel"
                style={{
                  marginBottom: 10
                }}
              >
                Gruppe medlemmer
              </div>

              <div
                style={{
                  marginTop: 20,
                  float: "left",
                  clear: "both"
                }}
              >
                <div className="inputFieldLabel">Vælg modtagere</div>
                <input
                  type="text"
                  placeholder="Søg efter brugere (email)"
                  value={this.state.searchValue}
                  style={{
                    width: "100%"
                  }}
                  onChange={(event: any) => {
                    this.setState({ searchValue: event.target.value.toLowerCase() });
                  }}
                />
                <div
                  className="searchButton"
                  onClick={() => {
                    const usersToRender = [];
                    this.setState({ loadingUsers: true });
                    AdminService.getUsers(this.state.searchValue).then((users: IUser[]) => {
                      if (users && users.length > 0) {
                        users.forEach(async (user: IUser) => {
                          usersToRender.push(
                            <div
                              className='emailLabel'
                              onClick={() => {
                                if (!this.props.appStore.groupToEdit.members) {
                                  this.props.appStore.groupToEdit.members = [];
                                }
                                this.props.appStore.groupToEdit.members.push(user.id);
                                this.setState({
                                  usersToRender: [],
                                  searchValue: undefined
                                });
                              }}
                            >
                              {`${user.name} (${user.username})`}
                            </div>
                          );
                        });
                        this.setState({ usersToRender, loadingUsers: false });
                      } else {
                        this.setState({ loadingUsers: false });
                      }
                    });
                  }}
                />
                {this.state.showMembers ? members : (this.props.appStore.groupToEdit?.members?.length === 1 ? "1 medlem" : `${this.props.appStore.groupToEdit?.members?.length ?? 0} medlemmer`)}
              </div>
              <div
                style={{
                  marginTop: 20,
                  float: "left",
                  clear: "both"
                }}
              >
                <button
                  onClick={() => this.props.save()}
                >
                  {this.props.appStore.groupToEdit.objectId !== undefined && this.props.appStore.groupToEdit.objectId !== "new" ? "Opdater" : "Opret"}
                </button>
                <button
                  style={{
                    marginLeft: 10
                  }}
                  onClick={() => {
                    this.props.appStore.groupToEdit = undefined;
                    this.props.appStore.productToNotify = undefined;
                  }}
                >
                  Annuller
                </button>
                <button
                  onClick={() => this.props.delete()}
                  style={{
                    backgroundColor: "gray",
                    marginLeft: 10,
                    display: this.props.appStore.groupToEdit && this.props.appStore.groupToEdit.objectId != undefined ? "block" : "hidden"
                  }}
                >
                  Slet
                </button>
              </div>
            </div>
          }
        </div>
        {this.state.usersToRender?.length > 0 &&
          <UserSearchPopup
            users={this.state.usersToRender}
            close={() => {
              this.setState({
                usersToRender: [],
                searchValue: undefined
              });
            }}
          />
        }
      </div>
    );
  }
}